<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="700"
  >
    <v-card :loading="isLoading">
      <v-card-title>
        <span class="headtitle-email"> {{$_strings.email.emailLabel}}</span>
        <v-row>
          <v-col class="d-flex justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container>
          <v-row>
            <v-col>
              <v-subheader>{{$_strings.email.nameFormatLabel}}</v-subheader>
            </v-col>
            <v-col cols="9">
              <v-text-field
                outlined
                dense
                v-model="form.picName"
                :rules="rules.picName"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-subheader>{{$_strings.email.emailLabel}}</v-subheader>
            </v-col>
            <v-col cols="9">
              <v-text-field
                outlined
                dense
                v-model="form.email"
                :rules="rules.email"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              outlined
              class="mr-2 ml-auto"
              @click="dialog = false"
            >
              {{$_strings.email.cancel}}
            </v-btn>
            <v-btn
              depressed
              color="primary"
              class="white--text"
              @click="submit"
              :disabled="isLoading"
            >
              {{$_strings.email.save}}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data() {
    return {
      isLoading: false,
      dialog: false,
      valid: false,
      id: null, // FOR EDIT EMAIL
      form: {
        picName: '',
        email: '',
      },
      rules: {
        picName: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.email.nameFormatLabel),
          (value) => {
            const pattern = /^[a-z ,.'-]+$/i;
            return pattern.test(value) || 'Tidak boleh menggunakan spesial character';
          },
        ],
        email: [
          (value) => {
            if (!value) {
              return this.$_strings.messages.validation.REQUIRED(this.$_strings.companyInformation.EMAIL);
            }
            return this.isEmailValid(value) || this.$_strings.messages.validation.EMAIL;
          },
        ],
      },
    };
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.isLoading = true;
      this.$_services.email.emailAddEdit(this.form, this.id)
        .then(() => {
          if (this.id) {
            this.$dialog.notify.success(this.$_strings.email.SUCCESS_EDIT_EMAIL_MESSAGE_TEXT);
          } else {
            this.$dialog.notify.success(this.$_strings.email.SUCCESS_CREATE_EMAIL_MESSAGE_TEXT);
          }
          this.$refs.form.reset();
          this.dialog = false;
          this.$emit('fetchEmail');
        }).finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.headtitle-email{
  font-size: 16px;
  font-weight: bold;
}
</style>
